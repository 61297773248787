<template>
  <div class="tutorial-home">
    <h2>【图文教程】</h2>
  <div>
       <h3>第一步：安装打印插件</h3>
    <p style="margin-bottom: 15px;">
      <span ><span style="display:inline-block;width:10px;height:10px;background-color:black;border-radius: 50%;margin-right: 15px;"></span>进入【口袋宝打单_快递单打印】后未安装插件系统会弹出按照提示，点击【立即下载】安装插件即可</span
      >
    </p>
     <span>
      <img
        :src="OSS+'/course_pic01.png'"
        alt=""
        style="margin: initial; max-width: 100%"
      />
    </span>
  </div>
  <div>
       <h3>第二步：设置发件人信息</h3>
    <p>
      <span>1、进入到基础设置页面</span
      >
    </p>
     <p>
      <span>2、设置快递单发件人信息并保存</span
      >
    </p>
     <span>
      <img
        :src="OSS+'/image_second.png'"
        alt=""
        style="margin: initial; max-width: 100%"
      />
    </span>
  </div>
  <div>
       <h3>第三步：开通过电子面单</h3>
    <p>
      <span>1、电子面单开通链接：</span>
       <a
        href="https://mms.pinduoduo.com/logistics/open"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style="color: #70B1E7"
        >https://mms.pinduoduo.com/logistics/open</a
      >
    </p>
     <p>
      <span>2、打开链接，在电子面单页面，点击申请开通服务。选择好自己要打印的电子面单进行开通。</span
      >
    </p>
  </div>
  
  <div>
    <h3>第四步：打印订单</h3>
    <p style="font-size:19px;">1、在批量打印中，选择好筛选订单的条件，点击查询找到需要打印的订单</p>
    <p style="font-size:19px;">2、选择快递单模版，勾选需要打印的订单，点击打印快递单</p>
    <p>
      <img
        :src="OSS+'/course_pic03.png'"
        alt=""
        style="margin: initial; max-width: 80%"
      />
        <!-- <img
        src="../../assets/tutorial/course_pic03.png"
        alt=""
        style="margin: initial; max-width: 100%"
      /> -->
    </p>
    <p style="font-size:19px;">3、选择发件人点击确定</p>
    <p>
      <img
      :src="OSS+'/course_pic04.png'"
        alt=""
        style="margin: initial; max-width: 80%"
      />
    </p>
    <p style="font-size:19px;">4、选择打印机、设置好打印份数后点击【打印快递单】</p>
    <img :src="OSS+'/course_pic05.png'" alt="" style="margin: initial; max-width: 80%">
  </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.navigation{
  display: none !important;
}
.tutorial-home{
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  
}
.tutorial-home > p,span{
  font-size: 19px;
}

</style>